
import React from 'react'


const About = () => {

    return (
        <div>
            
            This is About 
        </div>
    )
}
export default About;